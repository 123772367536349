<template>
  <div class="home-container" v-if="isAccountOwner || isTeacher">
    <GuruBreadcrumb :link-list="linkList"/>
    <div class="has-text-centered guru-ins-title" v-if="currentAssociation.institute">Assignments</div>
    <div class="has-text-centered">
      <button class="button is-info has-text-white has-text-weight-semibold" @click="addAssignment">Create New Assignment</button> &nbsp;&nbsp;
      <router-link class="button" :to="insHome">Close</router-link>
    </div>
    <div class="columns has-text-centered" v-show="assignments.length>0">
      <div class="column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop">
        <table class="table is-striped is-hoverable is-fullwidth">
          <thead>
          <tr>
            <th>Title</th>
            <th>Status</th>
            <th>Start</th>
            <th>End</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="assignment in assignments">
            <td><a class="btn btn-link" @click="viewAssignment(assignment)">{{assignment.title}}</a></td>
            <td>{{assignment.status}}</td>
            <td>{{getFormattedDate(assignment.startTime)}}</td>
            <td>{{getFormattedDate(assignment.endTime)}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import GuruBreadcrumb from '../GuruBreadcrumb'
import { eventBus } from '../../../assets/javascript/event-bus'
import formMixin from '../../../assets/javascript/form-mixin'
import { GURU_EVENTS } from '../../../assets/javascript/constants'
import moment from 'moment'

export default {
  name: 'guruAssignments',
  components: { GuruBreadcrumb },
  props: ['currentAssociation', 'isAccountOwner', 'isInstituteAdmin', 'isTeacher', 'isStudent', 'guruInitialised'],
  inject: ['$validator'],
  mixins: [formMixin],
  mounted () {
    eventBus.$on(GURU_EVENTS.ADD_TO_ASSIGNMENTS, (assignment) => {
      this.assignments.push(assignment)
      this.sortAssignments()
    })

    this.initAssignments()
  },
  beforeDestroy () {
    eventBus.$off(GURU_EVENTS.ADD_TO_ASSIGNMENTS)
  },
  data: function () {
    return {
      linkList: [
        {
          title: 'Guru Home',
          href: '/guru'
        },
        {
          title: 'Institute Home',
          href: '/guru/ins/' + this.$route.params.instituteCode
        }
      ],
      assignments: []
    }
  },
  methods: {
    initAssignments () {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/assignment/assignments',
        data:
          {
            'instituteCode': this.$route.params.instituteCode
          },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          this.assignments = data.assignments
          this.sortAssignments()
        },
        jdaEvent: 'get-assignments',
        jdaLabel: ''
      })
    },
    addAssignment () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('./GuruAddEditAssignment.vue'),
        { mode: 'add', instituteCode: this.$route.params.instituteCode })
    },
    viewAssignment (assignment) {
      this.$router.push('/guru/ins/' + this.$route.params.instituteCode + '/assignment/' + assignment.insAssignmentId)
    },
    sortAssignments () {
      this.assignments = window._.sortBy(this.assignments, (o) => { return o.title })
    },
    checkAuthentication () {
      if (!this.guruInitialised) {
        return
      }

      let location = '/guru/ins/' + this.$route.params.instituteCode

      if (!this.isAccountOwner && !this.isInstituteAdmin && !this.isTeacher) {
        this.$router.push(location)
      }
    },
    getFormattedDate: function (dte) {
      if (!dte) {
        return ''
      }

      return moment(dte).format('MMMM Do YYYY, h:mm:ss a')
    }
  },
  computed: {
    insHome () {
      return '/guru/ins/' + this.$route.params.instituteCode
    }
  },
  watch: {
    guruInitialised () {
      this.checkAuthentication()
    },
    currentAssociation () {
      this.checkAuthentication()
    }
  }
}
</script>

<style lang="scss">
  .guru-ins-title {
    font-size: 1.2em;
    color: rgb(88, 88, 90);
    font-weight: bold;
    margin-bottom: 20px;
  }
</style>
